import React, { useEffect, useState, useMemo } from "react";
import "./CSS/MaintenanceScreen.css";
import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import axios from "axios";
import Button from "@mui/material/Button";
import { pink, deepPurple } from "@mui/material/colors";
import { useUser } from "./Context/UserContext";
import { Alert, Snackbar } from "@mui/material";
import {
  Card,
  CardContent,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Container,
  CardHeader,
  Checkbox,
  Chip,
  Link,
  TablePagination,
  InputAdornment,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { tr } from "date-fns/locale";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  PieChart,
  Pie,
  ComposedChart,
  Area,
  AreaChart,
  Cell,
} from "recharts";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { TimePicker } from "@mui/x-date-pickers";
import { CoPresentSharp, Edit as EditIcon } from "@mui/icons-material";
import dayjs from "dayjs";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Warning as WarningIcon,
  Close as CloseIcon,
  Store as StoreIcon,
} from "@mui/icons-material";
import { set } from "date-fns";
import InfoIcon from "@mui/icons-material/Info";
import { Search } from "lucide-react";
import * as XLSX from "xlsx";
const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    variants: [
      {
        props: ({ open }) => open,
        style: {
          transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
          marginLeft: 0,
        },
      },
    ],
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(["margin", "width"], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const UserReports = () => {
  let status = "1";
  let path =
    status === "0" ? "http://localhost:3003" : "https://outlettee.com:3003";

  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [selectedMenu, setSelectedMenu] = useState("");
  const [userInfos, setUserInfos] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const { user } = useUser();
  console.log(user);
  const COLORS = [
    "#FA8072", // Somon
    "#FF5733", // Turuncu-kırmızı
    "#FFBD33", // Altın sarısı
    "#75FF33", // Açık yeşil
    "#33FF57", // Yeşil ton
    "#33FFBD", // Turkuaz ton
    "#33DBFF", // Açık mavi
    "#3375FF", // Mavi
    "#5733FF", // Mor ton
    "#BD33FF", // Eflatun
    "#FF33DB", // Pembe ton
    "#FF3375", // Pembe-kırmızı
    "#FF3333", // Kırmızı
    "#FF6F61", // Mercan
    "#FFD700", // Altın
    "#ADFF2F", // Yeşil-sarı
    "#7FFF00", // Chartreuse
    "#00FA9A", // Orman yeşili
    "#00CED1", // Koyu Turkuaz
    "#1E90FF", // Dodger Mavisi
    "#4169E1", // Kraliyet Mavisi
    "#8A2BE2", // Mavi-mor
    "#9400D3", // Koyu mor
    "#C71585", // Orkide
    "#FF1493", // Pembe
    "#FF7F50", // Mercan
    "#CD5C5C", // Koyu mercan
    "#F08080", // Açık mercan
    "#DBFF33", // Sarı-yeşil
    "#FF00FF", // Fuşya
  ];

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleMenuClick = (menu) => {
    setSelectedMenu(menu); // Menü tıklamalarını yakala
  };

  const [selectedUser, setSelectedUser] = useState("all");
  const [selectedShop, setSelectedShop] = useState("all");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedShopData, setSelectedShopData] = useState("all");
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [userEffortsFees, setUserEffortsFees] = useState([]);

  useEffect(() => {
    const fetchShopData = async () => {
      const UserID = user?.ID;
      const isAdmin = user?.Admin;
      try {
        const response = await axios.post(
          path + "/api/GetUserEffortViaParamaters",
          {
            UserID: UserID,
            isAdmin: isAdmin,
          }
        );
        const parsedData = response.data;
        setUserData(parsedData); // userData state'i burada güncelleniyor
      } catch (error) {
        console.error("Error fetching shop data:", error);
      }
    };

    fetchShopData();

    const fetchShopAuth = async () => {
      try {
        const response = await axios.post(path + "/api/getUserShops", {
          UserID: user?.ID,
        });
        const options = response.data.data.map((shop) => ({
          value: shop.ShopName,
          label: shop.ShopName,
        }));
        setOptions(options);
      } catch (error) {
        console.error("Error fetching shop data:", error);
      }
    };
    fetchShopAuth();

    const fetchShop = async () => {
      try {
        setData([]);
        const response = await axios.get(path + "/api/GetAllShopData");
        const parsedData = response.data;
        console.log(parsedData);
        setData(parsedData);
      } catch (error) {
        console.error("Error fetching shop data:", error);
      }
    };
    fetchShop();
  }, []);

  useEffect(() => {
    // userData değiştiğinde userEffortsFees'i güncelle
    if (userData && userData.length > 0) {
      const updatedEfforts = userData.map((user) => ({
        userId: user.UserID,
        userName: user.UserName,
        effortType1: "",
        effortType1Fee: "",
        effortType2: "",
        effortType2Fee: "",
      }));
      setUserEffortsFees(updatedEfforts);
    }
  }, [userData]); // userData'ya bağımlı

  useEffect(() => {
    const fetchUserFee = async () => {
      try {
        const response = await axios.get(path + "/api/AllEffortsFees");
        const parsedData = response.data;

        if (parsedData && parsedData.length > 0) {
          const updatedEfforts = userData.map((user) => {
            const existingEffort = parsedData.find(
              (effort) => effort.userId === user.UserID
            );

            return {
              userId: user.UserID,
              userName: user.UserName,
              effortType1: existingEffort?.effortType1 || "",
              effortType1Fee: existingEffort?.effortType1Fee || "",
              effortType2: existingEffort?.effortType2 || "",
              effortType2Fee: existingEffort?.effortType2Fee || "",
            };
          });

          setUserEffortsFees(updatedEfforts);
        }
      } catch (error) {
        console.error("Error fetching shop data:", error);
      }
    };

    fetchUserFee();
  }, [userData]);

  const [options, setOptions] = useState([]);

  // Benzersiz kullanıcı ve mağaza listelerini oluştur
  const users = [...new Set(userData.map((item) => item.UserName))];
  const shops = [...new Set(userData.map((item) => item.Shop))];

  const ShopData = () => {
    const formatCurrency = (value) =>
      Number(value).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });

    const formatCurrencyTable = (value) => {
      return new Intl.NumberFormat("tr-TR", {
        style: "currency",
        currency: "TRY",
        minimumFractionDigits: 2,
      }).format(value);
    };

    const [selectedShop, setSelectedShop] = useState("all");
    const [dateRange, setDateRange] = useState({
      start: "",
      end: "",
    });

    const Allshops = ["all", ...new Set(data.map((item) => item.SelectedShop))];

    const filteredData = data.filter((item) => {
      const itemDate = new Date(item.StartDate);
      const start = dateRange.start ? new Date(dateRange.start) : null;
      const end = dateRange.end ? new Date(dateRange.end) : null;

      const matchesShop =
        selectedShop === "all" || item.SelectedShop === selectedShop;
      const matchesDate =
        (!start || itemDate >= start) && (!end || itemDate <= end);

      return matchesShop && matchesDate;
    });

    const formatDate = (dateStr) => new Date(dateStr).toLocaleDateString();

    const summaryData = {
      totalSales: filteredData.reduce((sum, item) => sum + item.totalSales, 0),
      totalRefunds: filteredData.reduce(
        (sum, item) => sum + item.totalRefundsAmounts,
        0
      ),
      totalFees: filteredData.reduce(
        (sum, item) =>
          sum + item.listingFees + item.marketingFees + item.ShippingFees,
        0
      ),
      netProfit: filteredData.reduce((sum, item) => sum + item.salesSummary, 0),
    };

    const salesData = filteredData.map((item) => ({
      date: formatDate(item.StartDate),
      sales: item.totalSales,
      refunds: item.totalRefunds,
      profit: item.salesSummary,
    }));

    const feesData = [
      {
        name: "Liste",
        value: filteredData.reduce((sum, item) => sum + item.listingFees, 0),
      },
      {
        name: "Pazarlama",
        value: filteredData.reduce((sum, item) => sum + item.marketingFees, 0),
      },
      {
        name: "Kargo",
        value: filteredData.reduce((sum, item) => sum + item.ShippingFees, 0),
      },
    ];

    const columns = [
      { field: "ID", headerName: "ID", width: 90 },
      { field: "Username", headerName: "Kullanıcı Adı", width: 130 },
      { field: "SelectedShop", headerName: "Mağaza", width: 130 },
      {
        field: "StartDate",
        headerName: "Başlangıç Tarihi",
        width: 130,
      },
      {
        field: "EndDate",
        headerName: "Bitiş Tarihi",
        width: 130,
      },
      {
        field: "totalSales",
        headerName: "Toplam Satış",
        width: 130,
      },
      {
        field: "totalRefundsAmounts",
        headerName: "İadeler",
        width: 130,
      },
      {
        field: "listingFees",
        headerName: "Liste Ücretleri",
        width: 130,
      },
      {
        field: "marketingFees",
        headerName: "Pazarlama Ücretleri",
        width: 130,
      },
      {
        field: "ShippingFees",
        headerName: "Kargo Ücretleri",
        width: 130,
      },
      {
        field: "salesSummary",
        headerName: "Net Kar",
        width: 130,
      },
    ];

    const COLORS = ["#1976d2", "#2e7d32", "#ed6c02"];

    return (
      <Box sx={{ p: 3 }}>
        <Grid container spacing={3}>
          {/* Filters */}
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <InputLabel>Mağaza Seç</InputLabel>
                      <Select
                        value={selectedShop}
                        label="Mağaza Seç"
                        onChange={(e) => setSelectedShop(e.target.value)}
                      >
                        {Allshops.map((shop) => (
                          <MenuItem key={shop} value={shop}>
                            {shop === "all" ? "Tüm Mağazalar" : shop}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      type="date"
                      label="Başlangıç Tarihi"
                      value={dateRange.start}
                      onChange={(e) =>
                        setDateRange({ ...dateRange, start: e.target.value })
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      type="date"
                      label="Bitiş Tarihi"
                      value={dateRange.end}
                      onChange={(e) =>
                        setDateRange({ ...dateRange, end: e.target.value })
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Summary Cards */}
          <Grid item xs={12} md={3}>
            <Card>
              <CardContent>
                <Typography variant="h6">Toplam Satış</Typography>
                <Typography variant="h4">
                  {formatCurrency(summaryData.totalSales)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card>
              <CardContent>
                <Typography variant="h6">Toplam İade</Typography>
                <Typography variant="h4">
                  {formatCurrency(summaryData.totalRefunds)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card>
              <CardContent>
                <Typography variant="h6">Toplam Ücret</Typography>
                <Typography variant="h4">
                  {formatCurrency(summaryData.totalFees)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card>
              <CardContent>
                <Typography variant="h6">Net Kar</Typography>
                <Typography variant="h4">
                  {formatCurrency(summaryData.netProfit)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Charts */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Satış Trendi
                </Typography>
                <Box sx={{ height: 400 }}>
                  <ResponsiveContainer>
                    <LineChart data={salesData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line
                        type="monotone"
                        dataKey="sales"
                        stroke={COLORS[0]}
                        name="Satışlar"
                      />
                      <Line
                        type="monotone"
                        dataKey="refunds"
                        stroke={COLORS[1]}
                        name="İadeler"
                      />
                      <Line
                        type="monotone"
                        dataKey="profit"
                        stroke={COLORS[2]}
                        name="Kar"
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Ücret Dağılımı
                </Typography>
                <Box sx={{ height: 400 }}>
                  <ResponsiveContainer>
                    <PieChart>
                      <Pie
                        data={feesData}
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={80}
                        fill="#8884d8"
                        paddingAngle={5}
                        dataKey="value"
                      >
                        {feesData.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Mağaza Performansı
                </Typography>
                <Box sx={{ height: 400 }}>
                  <ResponsiveContainer>
                    <BarChart data={filteredData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="SelectedShop" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar
                        dataKey="totalSales"
                        fill={COLORS[0]}
                        name="Toplam Satış"
                      />
                      <Bar
                        dataKey="salesSummary"
                        fill={COLORS[1]}
                        name="Net Kar"
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Data Table */}
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Detaylı Veri
                </Typography>
                <Box sx={{}}>
                  <DataGrid
                    rows={filteredData}
                    getRowId={(row) => row.ID}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10, 25, 50]}
                    components={{
                      Toolbar: GridToolbar,
                    }}
                    disableSelectionOnClick
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    );
  };

  // Filtrelenmiş veriyi hesapla
  const filteredData = useMemo(() => {
    return userData.filter((item) => {
      const userMatch =
        selectedUser === "all" || item.UserName === selectedUser;
      const shopMatch = selectedShop === "all" || item.Shop === selectedShop;

      // Tarih filtresi
      const itemDate = new Date(item.EffortDate);
      const dateMatch =
        (!startDate || itemDate >= startDate) &&
        (!endDate || itemDate <= endDate);

      return userMatch && shopMatch && dateMatch;
    });
  }, [selectedUser, selectedShop, userData, startDate, endDate]);

  const convertTimeSummaryToHours = (timeSummary) => {
    const [hours, minutes] = timeSummary.split(":").map(Number);
    // First convert everything to minutes
    const totalMinutes = hours * 60 + minutes;
    // Convert back to hours and round to nearest 0.5
    const roundedHours = Math.round((totalMinutes / 60) * 2) / 2;
    return roundedHours;
  };

  const totalCalculations = useMemo(() => {
    const calculations = filteredData.reduce(
      (total, item) => {
        const hours = convertTimeSummaryToHours(item.TimeSummary);

        // Kullanıcının fee bilgilerini bul
        const userFees = userEffortsFees.find(
          (fee) => fee.userId === item.UserID
        );

        if (!userFees) return total;

        // Shop'a göre fee seçimi ve hesaplama
        if (item.Shop === "Arge") {
          if (user.isAdmin === 1 || user.Username === selectedUser) {
            const hourlyFee = parseFloat(userFees.effortType2Fee);
            return {
              ...total,
              argeHours: total.argeHours + hours,
              argeCost: total.argeCost + hours * hourlyFee,
              hours: total.hours + hours,
              cost: total.cost + hours * hourlyFee,
            };
          } else {
            return {
              ...total,
              argeHours: total.argeHours + hours,
              argeCost: total.argeCost + 0, // ARGE maliyeti sıfır
              hours: total.hours + hours,
              cost: total.cost + 0, // ARGE için maliyet eklenmez
            };
          }
        } else {
          const hourlyFee = parseFloat(userFees.effortType1Fee);
          return {
            ...total,
            otherHours: total.otherHours + hours,
            otherCost: total.otherCost + hours * hourlyFee,
            hours: total.hours + hours,
            cost: total.cost + hours * hourlyFee,
          };
        }
      },
      {
        hours: 0,
        cost: 0,
        argeHours: 0,
        argeCost: 0,
        otherHours: 0,
        otherCost: 0,
      }
    );

    return {
      totalWorkHours: calculations.hours.toFixed(2),
      totalCost: calculations.cost.toFixed(2),
      argeWorkHours: calculations.argeHours.toFixed(2),
      argeCost: calculations.argeCost.toFixed(2),
      otherWorkHours: calculations.otherHours.toFixed(2),
      otherCost: calculations.otherCost.toFixed(2),
    };
  }, [filteredData, userEffortsFees, user, selectedUser]);

  const shopCalculations = useMemo(() => {
    const calculations = {};

    filteredData.forEach((item) => {
      if (item.Shop === "") return;

      const hours = convertTimeSummaryToHours(item.TimeSummary);
      const userFees = userEffortsFees.find(
        (fee) => fee.userId === item.UserID
      );

      if (!userFees) return;

      const hourlyFee =
        item.Shop === "ARGE"
          ? parseFloat(userFees.effortType2Fee)
          : parseFloat(userFees.effortType1Fee);

      if (!calculations[item.Shop]) {
        calculations[item.Shop] = { hours: 0, cost: 0 };
      }

      calculations[item.Shop].hours += hours;
      calculations[item.Shop].cost += hours * hourlyFee;
    });

    return Object.entries(calculations).map(([shop, calc]) => ({
      shop,
      hours: Number(calc.hours.toFixed(2)),
      cost: Number(calc.cost.toFixed(2)),
    }));
  }, [filteredData, userEffortsFees]);

  const totalWorkHours = useMemo(() => {
    return filteredData
      .reduce((total, item) => {
        return total + convertTimeSummaryToHours(item.TimeSummary);
      }, 0)
      .toFixed(2);
  }, [filteredData]);

  // Mağaza bazında toplam saatleri hesapla
  const shopHours = useMemo(() => {
    const hours = {};
    console.log(filteredData);
    filteredData.forEach((item) => {
      if (item.Shop === "") {
        return;
      }
      const timeSpent = convertTimeSummaryToHours(item.TimeSummary);
      hours[item.Shop] = (hours[item.Shop] || 0) + timeSpent;
    });

    return Object.entries(hours).map(([shop, hours]) => ({
      shop,
      hours: Number(hours.toFixed(2)),
    }));
  }, [filteredData]);

  const dailyEfforts = useMemo(() => {
    const effortsByDate = {};
    filteredData.forEach((item) => {
      const date = new Date(item.EffortDate).toLocaleDateString();
      const timeSpent = convertTimeSummaryToHours(item.TimeSummary);
      effortsByDate[date] = (effortsByDate[date] || 0) + timeSpent;
    });
    return Object.entries(effortsByDate).map(([date, hours]) => ({
      date,
      hours: Number(hours.toFixed(2)),
    }));
  }, [filteredData]);

  const taskDistribution = useMemo(() => {
    const tasks = {};
    filteredData.forEach((item) => {
      const taskList = item.SelectedEfforts.split(",");
      const timePerTask =
        convertTimeSummaryToHours(item.TimeSummary) / taskList.length;
      taskList.forEach((task) => {
        tasks[task.trim()] = (tasks[task.trim()] || 0) + timePerTask;
      });
    });
    return Object.entries(tasks).map(([name, value]) => ({
      name,
      value: Number(value.toFixed(2)),
    }));
  }, [filteredData]);

  const UserDetailReports = () => {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={tr}>
        <Box sx={{ p: 3 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
            }}
          >
            <Typography variant="h4" component="h1">
              Efor Raporu
            </Typography>

            <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
              <FormControl sx={{ minWidth: 200 }}>
                <InputLabel>Kullanıcı</InputLabel>
                <Select
                  value={selectedUser}
                  label="Kullanıcı"
                  onChange={(e) => setSelectedUser(e.target.value)}
                >
                  <MenuItem value="all">Tüm Kullanıcılar</MenuItem>
                  {users.map((user) => (
                    <MenuItem key={user} value={user}>
                      {user}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl sx={{ minWidth: 200 }}>
                <InputLabel>Mağaza</InputLabel>
                <Select
                  value={selectedShop}
                  label="Mağaza"
                  onChange={(e) => setSelectedShop(e.target.value)}
                >
                  <MenuItem value="all">Tüm Mağazalar</MenuItem>
                  {shops.map((shop) => (
                    <MenuItem key={shop} value={shop}>
                      {shop}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <DatePicker
                label="Başlangıç Tarihi"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
                format="dd/MM/yyyy"
              />

              <DatePicker
                label="Bitiş Tarihi"
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
                format="dd/MM/yyyy"
                minDate={startDate}
              />
            </Box>
          </Box>

          <Card sx={{ mb: 3 }}>
            <CardContent>
              <Typography variant="h6" color="primary">
                Toplam Çalışma Süresi: {totalWorkHours} saat
              </Typography>

              {(user.Admin === 1 || user.Username === selectedUser) && (
                <Typography variant="h6" color="secondary">
                  Toplam Maliyet: {totalCalculations.totalCost} USD
                  <Typography
                    component="span"
                    sx={{
                      color: "warning.main", // sarı renk
                      fontSize: "0.8em", // ana yazıdan daha küçük
                      marginLeft: 1, // sol boşluk
                    }}
                  >
                    (ARGE: {totalCalculations.argeCost} USD, Diğer:{" "}
                    {totalCalculations.otherCost} USD)
                  </Typography>
                </Typography>
              )}
            </CardContent>
          </Card>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Günlük Efor Dağılımı
                  </Typography>
                  <Box sx={{ height: 300 }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart data={dailyEfforts}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line
                          type="monotone"
                          dataKey="hours"
                          stroke="#1976d2"
                          name="Saat"
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Mağaza Bazlı Toplam Efor
                  </Typography>
                  <Box sx={{ height: 300 }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart data={shopHours}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="shop" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar
                          dataKey="hours"
                          fill="#2e7d32"
                          name="Toplam Saat"
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Görev Dağılımı
                  </Typography>
                  <Box sx={{ height: 300 }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart>
                        <Pie
                          data={taskDistribution}
                          dataKey="value"
                          nameKey="name"
                          cx="50%"
                          cy="50%"
                          outerRadius={100}
                          label
                        >
                          {/* Her dilim için farklı renk vermek */}
                          {taskDistribution.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </ResponsiveContainer>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Detaylı Efor Listesi
                  </Typography>
                  <EffortTable
                    filteredData={filteredData}
                    onUpdateEffort={async (updatedEffort) => {
                      // API çağrısı yapılacak
                      console.log(updatedEffort);
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </LocalizationProvider>
    );
  };

  const EffortTable = ({ filteredData, onUpdateEffort }) => {
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
    const [selectedEffort, setSelectedEffort] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    const handleSearchChange = (event) => {
      setSearchTerm(event.target.value);
    };

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    const searchedData = filteredData.filter((item) =>
      Object.values(item).some((value) =>
        value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    // Filtrelenmiş ve sayfalama uygulanmış veriler
    const paginatedData = searchedData.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
    // Arama terimini kullanarak verileri filtrele

    const handleEditClick = (effort) => {
      setSelectedEffort(effort);
      setEditDialogOpen(true);
    };

    const handleDetailsClick = (effort) => {
      console.log(effort);
      setSelectedEffort(effort);
      setDetailsDialogOpen(true);
    };

    const handleSave = async (updatedEffort) => {
      console.log(updatedEffort);
      try {
        const indexID = updatedEffort.Id;
        const selectedShop = updatedEffort.Shop;
        const UserID = user?.ID;
        const username = user?.Username;
        const startDate = dayjs(updatedEffort.EffortDate).format("DD/MM/YYYY");
        const startTime = updatedEffort.StartHour;
        const endTime = updatedEffort.EndHour;
        const description = updatedEffort.Explanation;
        const storeLinks = updatedEffort.ShopLinks;
        const sellerLinks = updatedEffort.PurchLinks;
        const SelectedEfforts = updatedEffort.SelectedEfforts.map(
          (item) => `${item}`
        ).join(", ");

        const start = dayjs(`1970-01-01T${updatedEffort.StartHour}`);
        let end = dayjs(`1970-01-01T${updatedEffort.EndHour}`);

        if (end.isBefore(start)) {
          end = end.add(1, "day");
        }

        const totalMinutes = end.diff(start, "minute");
        const hours = Math.floor(totalMinutes / 60); // 2
        const minutes = totalMinutes % 60; // 5

        // Dakika kısmını 2 basamakla padStart yapalım
        const formattedMinutes = String(minutes).padStart(2, "0"); // "05"
        const TimeSummary = `${hours}:${formattedMinutes}`;

        console.log(TimeSummary); // "2:05"
        // Zorunlu alanlar dolu mu diye kontrol
        if (!startDate || !startTime || !endTime) {
          setAlertSeverity("error");
          setAlertMessage("Lütfen tüm zorunlu alanları doldurunuz!");
          setAlertOpen(true);
          return;
        }

        // Kullanıcı bilgisi var mı?
        if (!UserID || !username) {
          setAlertSeverity("error");
          setAlertMessage(
            "Beklenmedik Bir Hata Oluştu. Lütfen Yöneticinizle İletişime Geçiniz!"
          );
          setAlertOpen(true);
          return;
        }

        // Sunucuya güncelleme isteği at
        const response = await axios.post(`${path}/api/UpdateFormData`, {
          indexID,
          UserID,
          username,
          selectedShop,
          startDate,
          startTime,
          endTime,
          SelectedEfforts,
          description,
          storeLinks,
          sellerLinks,
          TimeSummary,
        });

        // Başarılı ise alert göster
        setAlertSeverity("success");
        setAlertMessage("Efor başarıyla güncellendi.");
        setAlertOpen(true);
      } catch (error) {
        setAlertSeverity("error");
        setAlertMessage("Efor güncellenirken bir hata oluştu." + error);
        setAlertOpen(true);
      }
    };
    const DetailsDialog = ({ open, onClose, effort }) => {
      if (!effort) return null;

      // Helper function to check if base64 string is valid
      const isValidBase64 = (str) => {
        if (!str) return false;
        try {
          return str.startsWith("data:image") || btoa(atob(str)) === str;
        } catch (err) {
          return false;
        }
      };

      // Get valid images from effort
      const images = ["Image1", "Image2", "Image3"]
        .map((key) => effort[key])
        .filter(isValidBase64);

      return (
        <Dialog open={open}>
          <DialogTitle className="text-lg font-semibold">
            Ayrıntılı Bilgiler
          </DialogTitle>
          <DialogContent>
            <div className="space-y-6 p-4">
              <div className="space-y-2">
                <h3 className="text-base font-medium">Adet Sayısı:</h3>
                <p>{effort.Piace}</p>
              </div>
              {/* Mağaza Linkleri */}
              <div className="space-y-2">
                <h3 className="text-base font-medium">Mağaza Linkleri:</h3>
                {effort.ShopLinks ? (
                  <div className="space-y-2">
                    {effort.ShopLinks.split(",").map((link, index) => (
                      <a
                        key={index}
                        href={link.trim()}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="block text-blue-600 hover:text-blue-800 hover:underline"
                      >
                        {link.trim()}
                      </a>
                    ))}
                  </div>
                ) : (
                  <p className="text-gray-500">Link bulunmuyor</p>
                )}
              </div>

              {/* Satıcı Linkleri */}
              <div className="space-y-2">
                <h3 className="text-base font-medium">Satıcı Linkleri:</h3>
                {effort.PurchLinks ? (
                  <div className="space-y-2">
                    {effort.PurchLinks.split(",").map((link, index) => (
                      <a
                        key={index}
                        href={link.trim()}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="block text-blue-600 hover:text-blue-800 hover:underline"
                      >
                        {link.trim()}
                      </a>
                    ))}
                  </div>
                ) : (
                  <p className="text-gray-500">Link bulunmuyor</p>
                )}
              </div>

              {/* Resimler */}
              {images.length > 0 && (
                <div className="space-y-2">
                  <h3 className="text-base font-medium">Resimler:</h3>
                  <div className="grid grid-cols-1 gap-4 place-items-center">
                    {images.map((image, index) => (
                      <div key={index} className="relative">
                        <Box
                          component="img"
                          src={
                            image.startsWith("data:image")
                              ? image
                              : `data:image/jpeg;base64,${image}`
                          }
                          alt={`Resim ${index + 1}`}
                          sx={{
                            width: 400,
                            height: 400,
                            objectFit: "contain",
                            borderRadius: "8px",
                            backgroundColor: "gray",
                          }}
                          onError={(e) => {
                            e.target.src = "/api/placeholder/200/200";
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} variant="outline">
              Kapat
            </Button>
          </DialogActions>
        </Dialog>
      );
    };
    const effortOptions = [
      "MESAJLARA CEVAP",
      "CUSTOMHUB",
      "CUSTOM ORDER HAZIRLANMASI",
      "URUN BULMA",
      "URUN YUKLEME",
      "MAGAZA GENEL KONTROL",
      "TOPLANTI",
      "PIYASA ARSTIRMASI AR-GE",
      "REKLAM OPTIMIZASYONU",
      "URETIM TAKIP VE KONTROL",
      "DIZAYN CIZIMI",
      "MOCKUP EKLEME",
      "TRADEMARK KONTROLU",
      "ARGE",
      "FASHION FOLLOW",
      "YONETIM VE KONTROL",
      "DIGER",
    ];
    return (
      <div className="space-y-4">
        {/* Arama Alanı */}

        <EditEffortDialog
          open={editDialogOpen}
          onClose={() => setEditDialogOpen(false)}
          effort={selectedEffort || {}}
          onSave={handleSave}
          shops={shops || []}
          allEffortTypes={effortOptions || []}
        />

        {/* Details Dialog */}
        <DetailsDialog
          open={detailsDialogOpen}
          onClose={() => setDetailsDialogOpen(false)}
          effort={selectedEffort}
        />

        <div className="flex items-center space-x-2 p-4 bg-white rounded-lg shadow">
          <Search className="text-gray-400" />
          <input
            type="text"
            placeholder="Ara..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Tablo */}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>İşlemler</TableCell>
                <TableCell>Tarih</TableCell>
                <TableCell>Kullanıcı</TableCell>
                <TableCell>Mağaza</TableCell>
                <TableCell>Başlangıç</TableCell>
                <TableCell>Bitiş</TableCell>
                <TableCell>Toplam Süre</TableCell>
                <TableCell>Görevler</TableCell>
                <TableCell>Ayrıntılar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.map((item) => (
                <TableRow key={item.Id}>
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={() => handleEditClick(item)}
                      color="primary"
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    {new Date(item.EffortDate).toLocaleDateString()}
                  </TableCell>
                  <TableCell>{item.UserName}</TableCell>
                  <TableCell>{item.Shop}</TableCell>
                  <TableCell>{item.StartHour}</TableCell>
                  <TableCell>{item.EndHour}</TableCell>
                  <TableCell>{item.TimeSummary}</TableCell>
                  <TableCell>
                    {item.SelectedEfforts === "DIGER"
                      ? `${item.SelectedEfforts} -> ${item.Explanation}`
                      : item.SelectedEfforts}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={() => handleDetailsClick(item)}
                      color="info"
                    >
                      <InfoIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {/* Sayfalama */}
          <TablePagination
            component="div"
            count={searchedData.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 20, 50]}
            labelRowsPerPage="Sayfa başına satır:"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} arası, toplam: ${count}`
            }
          />
        </TableContainer>
      </div>
    );
  };

  const EditEffortDialog = ({
    effort,
    open,
    onClose,
    onSave,
    shops,
    allEffortTypes,
  }) => {
    const [formData, setFormData] = useState({
      StartHour: effort.StartHour || "",
      EndHour: effort.EndHour || "",
      Shop: effort.Shop || "",
      SelectedEfforts: Array.isArray(effort.SelectedEfforts)
        ? effort.SelectedEfforts
        : effort.SelectedEfforts?.split(",").map((item) => item.trim()) || [],
      Explanation: effort.Explanation || "",
      ShopLinks: effort.ShopLinks || "",
      PurchLinks: effort.PurchLinks || "",
    });

    const handleChange = (e) => {
      const { name, value } = e.target;
      if (name === "SelectedEfforts") {
        setFormData((prev) => ({
          ...prev,
          SelectedEfforts: typeof value === "string" ? value.split(",") : value,
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    };

    const handleSubmit = () => {
      onSave({ ...effort, ...formData });
      onClose();
    };

    return (
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Efor Kaydını Güncelle</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 2,
              mt: 2,
            }}
          >
            <FormControl fullWidth sx={{}}>
              <TextField
                name="StartHour"
                label="Başlangıç Saati"
                type="time"
                value={formData.StartHour}
                onChange={handleChange}
                fullWidth
                InputLabelProps={{ shrink: true }}
                sx={{ mb: 2 }}
              />
            </FormControl>

            <FormControl fullWidth sx={{}}>
              <TextField
                name="EndHour"
                label="Bitiş Saati"
                type="time"
                value={formData.EndHour}
                onChange={handleChange}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Box>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Mağaza</InputLabel>
            <Select
              name="Shop"
              value={formData.Shop}
              onChange={handleChange}
              label="Mağaza"
            >
              {shops.map((shop, index) => (
                <MenuItem key={index} value={shop}>
                  {shop}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Yapılan İşler</InputLabel>
            <Select
              name="SelectedEfforts"
              multiple
              value={formData.SelectedEfforts}
              onChange={handleChange}
              label="Yapılan İşler"
            >
              {allEffortTypes.map((effort, index) => (
                <MenuItem key={index} value={effort}>
                  {effort}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              name="Explanation"
              label="Açıklama"
              multiline
              rows={4}
              value={formData.Explanation}
              onChange={handleChange}
              fullWidth
            />
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              name="storeLinks"
              label="Mağaza Linkleri"
              multiline
              rows={2}
              value={formData.ShopLinks}
              onChange={handleChange}
              fullWidth
            />
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              name="sellerLinks"
              label="Satıcı Linkleri"
              multiline
              rows={2}
              value={formData.PurchLinks}
              onChange={handleChange}
              fullWidth
            />
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} color="primary">
            İptal
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Güncelle
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const CompareShops = () => {
    const [selectedShops, setSelectedShops] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [filteredData, setFilteredData] = useState([]);

    const uniqueShops = useMemo(() => {
      const uniqueMap = new Map();
      data.forEach((shop) => {
        if (!uniqueMap.has(shop.SelectedShop)) {
          uniqueMap.set(shop.SelectedShop, shop);
        }
      });
      return Array.from(uniqueMap.values());
    }, [data]);

    // Filter shops based on search term
    const filteredShops = useMemo(() => {
      return uniqueShops.filter(
        (shop) =>
          shop.SelectedShop.toLowerCase().includes(searchTerm.toLowerCase()) ||
          shop.Username.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }, [uniqueShops, searchTerm]);

    // Cemil Bey'in gireceği değerler için state
    const [customExpenses, setCustomExpenses] = useState({
      monthlyOnlineSubscription: 0, // Aylık online abonelik gideri
      otherMonthlyExpenses: 0, // Diğer aylık giderler
    });

    // Hedef değerler için state
    const [targetMetrics, setTargetMetrics] = useState({
      marketingPercentage: 30, // Hedef marketing yüzdesi
      totalFeePercentage: 35, // Hedef toplam fee yüzdesi
    });

    useEffect(() => {
      if (!startDate || !endDate) {
        setFilteredData(selectedShops);
        return;
      }

      const filtered = selectedShops.filter((shop) => {
        const shopStart = new Date(shop.StartDate);
        const shopEnd = new Date(shop.EndDate);
        return (
          (shopStart >= startDate && shopStart <= endDate) ||
          (shopEnd >= startDate && shopEnd <= endDate) ||
          (shopStart <= startDate && shopEnd >= endDate)
        );
      });

      // Günlük gider hesaplaması
      const daysDiff = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
      const dailySubscription =
        (customExpenses.monthlyOnlineSubscription / 30) * daysDiff;
      const dailyOtherExpenses =
        (customExpenses.otherMonthlyExpenses / 30) * daysDiff;

      const enhancedData = filtered.map((shop) => ({
        ...shop,
        ...calculateMetrics(
          shop,
          dailySubscription,
          dailyOtherExpenses,
          userEarnings
        ),
      }));

      setFilteredData(enhancedData);
    }, [selectedShops, startDate, endDate, customExpenses]);

    const handleShopToggle = (shop) => {
      setSelectedShops((prev) => {
        const isSelected = prev.some((s) => s.ID === shop.ID);
        if (isSelected) {
          return prev.filter((s) => s.ID !== shop.ID);
        } else {
          return [...prev, shop];
        }
      });
    };

    const calculateMetrics = (
      shop,
      dailySubscription,
      dailyOtherExpenses,
      userEarnings
    ) => {
      console.log(userEarnings);

      // Get shop-specific earnings from userEarnings
      let shopEarnings = 0;
      Object.values(userEarnings).forEach((user) => {
        if (user.shops[shop]) {
          shopEarnings += user.shops[shop].earnings || 0;
        }
      });

      // Toplam Kar Hesabı
      const shopExpenses =
        shop.ShippingFeesCSV +
        shop.productCustomCost +
        shop.returnAndExchange +
        shop.productTotalSum;

      const totalExpenses = shopExpenses;
      const totalProfit = shop.PaymentFee - totalExpenses;
      const etsyMoneyDeposit =
        shop.salesSummary -
        (shop.listingFees + shop.marketingFees + shop.ShippingFees);
      const shippingExpenses = shop.ShippingFees;
      const totalSalesAmount = shop.CSVSalesSummary;
      const totalRefundAmount = shop.totalRefundsAmounts;
      const totalMarketingFees = shop.marketingFees;
      const totalFees = shop.listingFees;
      const shippingFees = shop.ShippingFees;
      const tpcExpenses =
        shop.productTotalSum + shop.productCustomCost + shop.returnAndExchange;

      let profitPerUnit = 0;
      if (totalSalesAmount > 0) {
        profitPerUnit = totalProfit / totalSalesAmount;
      } else {
        console.warn("Toplam satış adedi sıfır, adet başına kâr hesaplanamıyor.");
      }

      let marketingPerUnit = 0;
      if (totalSalesAmount > 0) {
        marketingPerUnit = totalMarketingFees / totalSalesAmount;
      } else {
        console.warn("Toplam satış adedi sıfır, adet başına kâr hesaplanamıyor.");
      }

      let FeesPerUnit = 0;
      if (totalSalesAmount > 0) {
        FeesPerUnit = totalFees / totalSalesAmount;
      } else {
        console.warn("Toplam satış adedi sıfır, adet başına kâr hesaplanamıyor.");
      }

      let shipPerUnit = 0;
      if (totalSalesAmount > 0) {
        shipPerUnit = shippingExpenses / totalSalesAmount;
      } else {
        console.warn("Toplam satış adedi sıfır, adet başına kâr hesaplanamıyor.");
      }

      let TPCPerUnit = 0;
      if (totalSalesAmount > 0) {
        TPCPerUnit = tpcExpenses / totalSalesAmount;
      } else {
        console.warn("Toplam satış adedi sıfır, adet başına kâr hesaplanamıyor.");
      }

      return {
        totalProfit,
        etsyMoneyDeposit,
        shippingExpenses,
        totalSalesAmount,
        totalRefundAmount,
        totalMarketingFees,
        totalFees,
        shippingFees,
        tpcExpenses,
        profitPerUnit,
        marketingPerUnit,
        FeesPerUnit,
        shipPerUnit,
        TPCPerUnit,
      };
    };

    const MetricCard = ({ title, values }) => (
      <Paper elevation={2} sx={{ p: 2, height: "100%" }}>
        <Typography color="textSecondary" gutterBottom>
          {title}
        </Typography>
        {values.map((value, index) => (
          <Chip
            key={index}
            label={value}
            sx={{ m: 0.5 }}
            color={index === 0 ? "primary" : "default"}
          />
        ))}
      </Paper>
    );

    return (
      <Box sx={{ display: "flex", height: "100vh" }}>
        {/* Sol Menü */}
        <Paper
          elevation={3}
          sx={{
            width: 280,
            flexShrink: 0,
            overflow: "auto",
            borderRight: 1,
            borderColor: "divider",
          }}
        >
          <Box sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Mağazalar ({selectedShops.length} seçili)
            </Typography>

            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={tr}
            >
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: 2, mb: 2 }}
              >
                <DatePicker
                  label="Başlangıç Tarihi"
                  value={startDate}
                  onChange={setStartDate}
                  format="dd/MM/yyyy"
                  slotProps={{
                    textField: { size: "small", fullWidth: true },
                  }}
                />
                <DatePicker
                  label="Bitiş Tarihi"
                  value={endDate}
                  onChange={setEndDate}
                  format="dd/MM/yyyy"
                  slotProps={{
                    textField: { size: "small", fullWidth: true },
                  }}
                />
              </Box>
            </LocalizationProvider>

            {/* Hedef Değerler ve Custom Giderler Input Alanı */}
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle2" gutterBottom>
                Hedef Metrikler
              </Typography>
              <TextField
                label="Hedef Marketing %"
                type="number"
                value={targetMetrics.marketingPercentage}
                onChange={(e) =>
                  setTargetMetrics((prev) => ({
                    ...prev,
                    marketingPercentage: Number(e.target.value),
                  }))
                }
                size="small"
                fullWidth
                sx={{ mb: 1 }}
              />
              <TextField
                label="Hedef Fee %"
                type="number"
                value={targetMetrics.totalFeePercentage}
                onChange={(e) =>
                  setTargetMetrics((prev) => ({
                    ...prev,
                    totalFeePercentage: Number(e.target.value),
                  }))
                }
                size="small"
                fullWidth
                sx={{ mb: 2 }}
              />

              <Typography variant="subtitle2" gutterBottom>
                Aylık Giderler
              </Typography>
              <TextField
                label="Online Abonelik"
                type="number"
                value={customExpenses.monthlyOnlineSubscription}
                onChange={(e) =>
                  setCustomExpenses((prev) => ({
                    ...prev,
                    monthlyOnlineSubscription: Number(e.target.value),
                  }))
                }
                size="small"
                fullWidth
                sx={{ mb: 1 }}
              />
              <TextField
                label="Diğer Giderler"
                type="number"
                value={customExpenses.otherMonthlyExpenses}
                onChange={(e) =>
                  setCustomExpenses((prev) => ({
                    ...prev,
                    otherMonthlyExpenses: Number(e.target.value),
                  }))
                }
                size="small"
                fullWidth
              />

              <TextField
                fullWidth
                size="small"
                label="Mağaza Ara"
                variant="outlined"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={{ mb: -1, mt: 4 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>

          <List>
            {filteredShops.length > 0 ? (
              filteredShops.map((shop) => (
                <ListItem
                  key={shop.ID}
                  onClick={() => handleShopToggle(shop)}
                  sx={{ cursor: "pointer" }}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={selectedShops.some((s) => s.ID === shop.ID)}
                    />
                  </ListItemIcon>
                  <ListItemIcon>
                    <StoreIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary={shop.SelectedShop}
                    secondary={shop.Username}
                  />
                </ListItem>
              ))
            ) : (
              <ListItem>
                <ListItemText
                  primary="Mağaza bulunamadı"
                  sx={{ textAlign: "center", color: "text.secondary" }}
                />
              </ListItem>
            )}
          </List>
        </Paper>

        {/* Ana İçerik */}
        <Box sx={{ flexGrow: 1, p: 3, overflow: "auto" }}>
          {filteredData.length > 0 ? (
            <>
              {/* Yeni Metrik Kartları */}
              <Grid container spacing={3} sx={{ mb: 4 }}>
              <Grid item xs={12} md={3}>
                <MetricCard
                  title="Toplam Kar"
                  values={filteredData.map(
                    shop => `${shop.SelectedShop} ${Number(shop.totalProfit).toLocaleString("tr-TR", {
                      style: "currency",
                      currency: "USD",
                    })}`
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <MetricCard
                  title="Etsy Yatacak Para"
                  values={filteredData.map(
                    shop => `${shop.SelectedShop} ${Number(shop.etsyMoneyDeposit).toLocaleString("tr-TR", {
                      style: "currency",
                      currency: "USD",
                    })}`
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <MetricCard
                  title="Kargo Giderleri"
                  values={filteredData.map(
                    shop => `${shop.SelectedShop} ${Number(shop.shippingExpenses).toLocaleString("tr-TR", {
                      style: "currency",
                      currency: "USD",
                    })}`
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <MetricCard
                  title="Toplam Satış Tutarı"
                  values={filteredData.map(
                    shop => `${shop.SelectedShop} ${Number(shop.totalSalesAmount).toLocaleString("tr-TR", {
                      style: "currency",
                      currency: "USD",
                    })}`
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <MetricCard
                  title="Toplam İade Tutarı"
                  values={filteredData.map(
                    shop => `${shop.SelectedShop} ${Number(shop.totalRefundAmount).toLocaleString("tr-TR", {
                      style: "currency",
                      currency: "USD",
                    })}`
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <MetricCard
                  title="Toplam Marketing Giderleri"
                  values={filteredData.map(
                    shop => `${shop.SelectedShop} ${Number(shop.totalMarketingFees).toLocaleString("tr-TR", {
                      style: "currency",
                      currency: "USD",
                    })}`
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <MetricCard
                  title="Toplam Ücretler"
                  values={filteredData.map(
                    shop => `${shop.SelectedShop} ${Number(shop.totalFees).toLocaleString("tr-TR", {
                      style: "currency",
                      currency: "USD",
                    })}`
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <MetricCard
                  title="TPC Giderleri"
                  values={filteredData.map(
                    shop => `${shop.SelectedShop} ${Number(shop.tpcExpenses).toLocaleString("tr-TR", {
                      style: "currency",
                      currency: "USD",
                    })}`
                  )}
                />
              </Grid>
            </Grid>

              <Grid container spacing={3} sx={{ mb: 4 }}>
                {/* Existing metric cards remain the same */}

                {/* New metric cards */}
                <Grid item xs={12} md={3}>
                  <MetricCard
                    title="Adet Başına Kar"
                    values={filteredData.map(
                      (shop) =>
                        `${shop.SelectedShop} ${Number(
                          shop.profitPerUnit
                        ).toLocaleString("tr-TR", {
                          style: "currency",
                          currency: "USD",
                        })}`
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <MetricCard
                    title="Adet Başına Marketing"
                    values={filteredData.map(
                      (shop) =>
                        `${shop.SelectedShop} ${Number(
                          shop.marketingPerUnit
                        ).toLocaleString("tr-TR", {
                          style: "currency",
                          currency: "USD",
                        })}`
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <MetricCard
                    title="Adet Başına Fees"
                    values={filteredData.map(
                      (shop) =>
                        `${shop.SelectedShop} ${Number(
                          shop.FeesPerUnit
                        ).toLocaleString("tr-TR", {
                          style: "currency",
                          currency: "USD",
                        })}`
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <MetricCard
                    title="Adet Başına Kargo"
                    values={filteredData.map(
                      (shop) =>
                        `${shop.SelectedShop} ${Number(
                          shop.shipPerUnit
                        ).toLocaleString("tr-TR", {
                          style: "currency",
                          currency: "USD",
                        })}`
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <MetricCard
                    title="Adet Başına TPC Gideri"
                    values={filteredData.map(
                      (shop) =>
                        `${shop.SelectedShop} ${Number(
                          shop.TPCPerUnit
                        ).toLocaleString("tr-TR", {
                          style: "currency",
                          currency: "USD",
                        })}`
                    )}
                  />
                </Grid>
              </Grid>

              {/* Grafikler */}
              <Grid container spacing={3} sx={{ mb: 4 }}>
        
      </Grid>

      {/* Temel Performans Grafikleri */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Gelir Dağılımı
              </Typography>
              <ResponsiveContainer width="100%" height={400}>
                <BarChart data={filteredData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="SelectedShop" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar 
                    dataKey="totalSalesAmount" 
                    name="Toplam Ciro" 
                    fill="#1976d2" 
                  />
                  <Bar 
                    dataKey="totalProfit" 
                    name="Net Kar" 
                    fill="#4caf50" 
                  />
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Gider Dağılımı
              </Typography>
              <ResponsiveContainer width="100%" height={400}>
                <PieChart>
                  <Pie
                    data={filteredData}
                    dataKey="totalFees"
                    nameKey="SelectedShop"
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    label
                  >
                    {filteredData.map((entry, index) => (
                      <Cell 
                        key={`cell-${index}`} 
                        fill={COLORS[index % COLORS.length]} 
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Detaylı Analizler */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Maliyet Analizi
              </Typography>
              <ResponsiveContainer width="100%" height={400}>
                <AreaChart data={filteredData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="SelectedShop" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Area 
                    type="monotone" 
                    dataKey="totalMarketingFees" 
                    name="Marketing" 
                    fill="#ff9800" 
                    stroke="#ff9800" 
                  />
                  <Area 
                    type="monotone" 
                    dataKey="shippingExpenses" 
                    name="Kargo" 
                    fill="#e91e63" 
                    stroke="#e91e63" 
                  />
                  <Area 
                    type="monotone" 
                    dataKey="tpcExpenses" 
                    name="TPC" 
                    fill="#9c27b0" 
                    stroke="#9c27b0" 
                  />
                </AreaChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Birim Başı Maliyetler
              </Typography>
              <ResponsiveContainer width="100%" height={400}>
                <LineChart data={filteredData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="SelectedShop" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line 
                    type="monotone" 
                    dataKey="marketingPerUnit" 
                    name="Birim Marketing" 
                    stroke="#f44336" 
                  />
                  <Line 
                    type="monotone" 
                    dataKey="FeesPerUnit" 
                    name="Birim Ücret" 
                    stroke="#2196f3" 
                  />
                  <Line 
                    type="monotone" 
                    dataKey="shipPerUnit" 
                    name="Birim Kargo" 
                    stroke="#4caf50" 
                  />
                </LineChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Typography color="textSecondary">
                {selectedShops.length > 0
                  ? "Seçili tarih aralığında veri bulunamadı"
                  : "Lütfen karşılaştırma için mağaza seçin"}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  const calculateUserEarnings = () => {
    console.log(userData);
    const userEarnings = {};

    userData.forEach((record) => {
      if (!userEarnings[record.UserName]) {
        userEarnings[record.UserName] = {
          argeHours: 0,
          argeEarnings: 0,
          otherHours: 0,
          otherEarnings: 0,
          totalHours: 0,
          totalEarnings: 0,
          shops: {}, // Shop-specific earnings
        };
      }

      const hours =
        parseFloat(record.TimeSummary.split(":")[0]) +
        parseFloat(record.TimeSummary.split(":")[1]) / 60;

      // Find user's fee information
      const userFee = userEffortsFees.find(
        (fee) => fee.userId === record.UserID
      );

      if (!userFee) return;

      if (record.Shop === "Arge") {
        const argeRate = parseFloat(userFee.effortType2Fee) || 0;
        userEarnings[record.UserName].argeHours += hours;
        userEarnings[record.UserName].argeEarnings += hours * argeRate;
      } else {
        const shopRate = parseFloat(userFee.effortType1Fee) || 0;
        userEarnings[record.UserName].otherHours += hours;
        userEarnings[record.UserName].otherEarnings += hours * shopRate;

        // Add shop-specific earnings
        if (!userEarnings[record.UserName].shops[record.Shop]) {
          userEarnings[record.UserName].shops[record.Shop] = {
            hours: 0,
            earnings: 0,
          };
        }
        userEarnings[record.UserName].shops[record.Shop].hours += hours;
        userEarnings[record.UserName].shops[record.Shop].earnings +=
          hours * shopRate;
      }

      userEarnings[record.UserName].totalHours += hours;
      userEarnings[record.UserName].totalEarnings =
        userEarnings[record.UserName].argeEarnings +
        userEarnings[record.UserName].otherEarnings;
    });

    return userEarnings;
  };

  const userEarnings = calculateUserEarnings();

  // Filter data based on date range
  const filteredEarnings = React.useMemo(() => {
    if (!startDate && !endDate) return userEarnings;

    const filtered = {};
    userData.forEach((record) => {
      const recordDate = new Date(record.EffortDate);
      if (
        (!startDate || recordDate >= startDate) &&
        (!endDate || recordDate <= endDate)
      ) {
        // Include this record in calculations
        const userName = record.UserName;
        if (!filtered[userName]) {
          filtered[userName] = {
            argeHours: 0,
            argeEarnings: 0,
            otherHours: 0,
            otherEarnings: 0,
            totalHours: 0,
            totalEarnings: 0,
          };
        }

        const hours =
          parseFloat(record.TimeSummary.split(":")[0]) +
          parseFloat(record.TimeSummary.split(":")[1]) / 60;

        const userFee = userEffortsFees.find(
          (fee) => fee.userId === record.UserID
        );
        if (!userFee) return;

        if (record.Shop === "Arge") {
          const argeRate = parseFloat(userFee.effortType2Fee) || 0;
          filtered[userName].argeHours += hours;
          filtered[userName].argeEarnings += hours * argeRate;
        } else {
          const shopRate = parseFloat(userFee.effortType1Fee) || 0;
          filtered[userName].otherHours += hours;
          filtered[userName].otherEarnings += hours * shopRate;
        }

        filtered[userName].totalHours += hours;
        filtered[userName].totalEarnings =
          filtered[userName].argeEarnings + filtered[userName].otherEarnings;
      }
    });

    return filtered;
  }, [userData, userEffortsFees, startDate, endDate]);

  const exportToExcel = () => {
    const data = Object.entries(filteredEarnings)
      .filter(
        ([userName]) => selectedUser === "all" || userName === selectedUser
      )
      .map(([userName, earnings]) => ({
        Kullanıcı: userName,
        "Arge Saatleri": earnings.argeHours.toFixed(2),
        "Arge Kazancı": `$${earnings.argeEarnings.toFixed(2)}`,
        "Diğer Saatler": earnings.otherHours.toFixed(2),
        "Diğer Kazanç": `$${earnings.otherEarnings.toFixed(2)}`,
        "Toplam Saat": earnings.totalHours.toFixed(2),
        "Toplam Kazanç": `$${earnings.totalEarnings.toFixed(2)}`,
      }));

    const worksheet = XLSX.utils.json_to_sheet(data);

    // Tüm kolonları al
    const columnsArray = Object.keys(data[0]);

    // Her kolonun genişliğini ayarla
    const columnWidths = columnsArray.map((col) => ({ wch: col.length + 2 }));
    worksheet["!cols"] = columnWidths;

    // Style özelliklerini tanımla
    const headerStyle = {
      fill: {
        fgColor: { rgb: "90EE90" }, // Açık yeşil renk
        type: "pattern",
        patternType: "solid",
      },
      font: {
        bold: true,
      },
      alignment: {
        horizontal: "center",
        vertical: "center",
      },
    };

    // Her kolon başlığına style uygula
    columnsArray.forEach((col, idx) => {
      const cellRef = XLSX.utils.encode_cell({ r: 0, c: idx });
      worksheet[cellRef].s = headerStyle;
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Kazanç Özeti");

    // Dosyayı kaydet
    XLSX.writeFile(workbook, "Kazanç_Özeti.xlsx");
  };

  const UserEarningsScreen = () => {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div style={{ padding: "24px" }}>
          <Grid container spacing={3}>
            {/* Date Range Selection */}
            <Grid item xs={12} md={8}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Tarih Aralığı
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <DatePicker
                        label="Başlangıç Tarihi"
                        value={startDate}
                        onChange={setStartDate}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <DatePicker
                        label="Bitiş Tarihi"
                        value={endDate}
                        onChange={setEndDate}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            {/* User Filter */}
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Kullanıcı Filtresi
                  </Typography>
                  <FormControl fullWidth>
                    <InputLabel>Kullanıcı Seç</InputLabel>
                    <Select
                      value={selectedUser}
                      onChange={(e) => setSelectedUser(e.target.value)}
                      label="Kullanıcı Seç"
                    >
                      <MenuItem value="all">Tüm Kullanıcılar</MenuItem>
                      {Object.keys(filteredEarnings).map((userName) => (
                        <MenuItem key={userName} value={userName}>
                          {userName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </CardContent>
              </Card>
            </Grid>

            {/* Earnings Table */}
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Kazanç Özeti
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginBottom: "16px" }}
                    onClick={exportToExcel}
                  >
                    Excel'e Aktar
                  </Button>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Kullanıcı</TableCell>
                          <TableCell>Arge Saatleri</TableCell>
                          <TableCell>Arge Kazancı</TableCell>
                          <TableCell>Diğer Saatler</TableCell>
                          <TableCell>Diğer Kazanç</TableCell>
                          <TableCell>Toplam Saat</TableCell>
                          <TableCell>Toplam Kazanç</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.entries(filteredEarnings)
                          .filter(
                            ([userName]) =>
                              selectedUser === "all" ||
                              userName === selectedUser
                          )
                          .map(([userName, earnings]) => (
                            <TableRow key={userName}>
                              <TableCell>{userName}</TableCell>
                              <TableCell>
                                {earnings.argeHours.toFixed(2)}
                              </TableCell>
                              <TableCell>
                                ${earnings.argeEarnings.toFixed(2)}
                              </TableCell>
                              <TableCell>
                                {earnings.otherHours.toFixed(2)}
                              </TableCell>
                              <TableCell>
                                ${earnings.otherEarnings.toFixed(2)}
                              </TableCell>
                              <TableCell>
                                {earnings.totalHours.toFixed(2)}
                              </TableCell>
                              <TableCell style={{ fontWeight: "bold" }}>
                                ${earnings.totalEarnings.toFixed(2)}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </LocalizationProvider>
    );
  };

  return (
    <>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={() => setAlertOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={() => setAlertOpen(false)} severity={alertSeverity}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          // Artık sabit yükseklik kullanmıyoruz.
          // height: "100vh",
          minHeight: "100vh", // sayfa boyu kadar arkaplanın uzaması için
          bgcolor: "rgb(180, 190, 190)",
          p: 2,
          overflow: "auto",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            open={open}
            style={{ backgroundColor: "#405D72" }}
          >
            <Toolbar style={{ backgroundColor: "#405D72" }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={[
                  {
                    mr: 2,
                  },
                  open && { display: "none" },
                ]}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap component="div">
                Kullanıcı Raporları
              </Typography>
            </Toolbar>
          </AppBar>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
                backgroundColor: "#DDE7F2",
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            <Toolbar>
              <IconButton onClick={handleDrawerClose}>
                {<ChevronLeftIcon />}
              </IconButton>
            </Toolbar>
            <Divider />
            <List>
              {[
                "Kullanıcı Raporları",
                "Shop Raporları",
                "Karşılaştırmalı Raporlar",
                "Kullanıcı Ücretleri",
              ].map((text, index) => (
                <ListItem key={text} disablePadding>
                  <ListItemButton onClick={() => handleMenuClick(text)}>
                    <ListItemIcon>
                      {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Divider />
          </Drawer>
          <Box component="main" sx={{ flexGrow: 1, p: 3, maxHeight: "100vh" }}>
            <Toolbar />
            {/* Menüye göre dinamik içerik */}
            {selectedMenu === "Kullanıcı Raporları" && (
              <Typography>
                <UserDetailReports />
              </Typography>
            )}
            {selectedMenu === "Shop Raporları" && (
              <Typography>
                {user.Admin === 1 ? <ShopData /> : "Yetkiniz yok!"}
              </Typography>
            )}
            {/* Diğer menü öğelerine içerik eklenebilir */}
            {selectedMenu === "Karşılaştırmalı Raporlar" && (
              <Typography>
                {user.Admin === 1 ? <CompareShops /> : "Yetkiniz yok!"}
              </Typography>
            )}
            {selectedMenu === "Kullanıcı Ücretleri" && (
              <Typography>
                {user.Admin === 1 ? <UserEarningsScreen /> : "Yetkiniz yok!"}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default UserReports;
