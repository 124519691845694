import React, { useEffect, useState } from "react";
import "./CSS/App.css";
import Button from "@mui/material/Button";
import SvgConverter from "./SvgConverter";
import Trademark from "./Trademark";
import SvgAdminPanel from "./SvgAdminPanel";
import Menu from "./Menu";
import LoginScreen from "./LoginScreen";
import MaintenanceScreen from "./MaintenanceScreen";
import ShopEffort from "./ShopEffort";
import UserEffort from "./UserEffort";
import UserReports from "./UserReports";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  Navigate,
} from "react-router-dom";
import { styled } from "@mui/material/styles";
import { UserProvider } from "./Context/UserContext";


const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("isAuthenticated") === "true"
  );

  useEffect(() => {
    localStorage.setItem("isAuthenticated", isAuthenticated);
  }, [isAuthenticated]);

  return (
     <UserProvider>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              isAuthenticated ? <Menu /> : <Navigate to="/LoginScreen" />
            }
          />
          <Route
            path="/LoginScreen"
            element={<LoginScreen onLogin={() => setIsAuthenticated(true)} />}
          />
          <Route path="/converter" element={<SvgConverter />} />
          <Route path="/trademark" element={<Trademark />} />
          <Route path="/SvgAdminPanel" element={<SvgAdminPanel />} />
          <Route path="/MaintenanceScreen" element={<MaintenanceScreen />} />
          <Route path="/UserEffort" element={<UserEffort />} />
          <Route path="/ShopEffort" element={<ShopEffort />} />
          <Route path="/UserReports" element={<UserReports />} />

        </Routes>
      </Router>
    </UserProvider>
  );
};


export default App;
